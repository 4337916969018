import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/cookie-richtlinien"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">&nbsp;</div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1 />
                    <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                      <div className="field-items">
                        <div
                          className="field-item even"
                          property="content:encoded"
                        >
                          <h1>Cookie-Richtlinie</h1>
                          <p>
                            Diese Cookie-Richtlinie verfolgt den Zweck, Sie über unsere Vorgehensweisen in Bezug auf Ihre Daten aufzuklären, die wir mithilfe von Cookies und anderen Tracking-Technologien erfassen (z.B. Gifs, Web Beacons, etc.)
                          </p>
                          <p>
                            Die Funktionalitäten, für die wir diese Technologien einsetzen, können Folgendes beinhalten:
                          </p>
                          <ul>
                            <li>Sie in der Navigation zu unterstützen,</li>
                            <li>
                              Sie dabei zu unterstützen, sich für unsere Veranstaltungen zu registrieren, beim Einloggen und bei Ihren Möglichkeiten, uns Feedback zu geben,
                            </li>
                            <li>
                              die Verwendung unserer Produkte, Dienstleistungen oder Anwendungen zu analysieren,
                            </li>
                            <li>
                              Sie mit unseren Marketing-Aktivitäten zu unterstützen (incl. interessensangepasste Werbung),
                            </li>
                            <li>
                              Inhalte von Dritten anzubieten (z.B. Social Media Inhalte).
                            </li>
                          </ul>
                          <p>
                            Am Ende dieser Richtlinie finden Sie eine detaillierte Auflistung der Cookies mit Beschreibung. Wir klassifizieren Cookies entsprechend den nachfolgenden Kategorien:
                          </p>
                          <ul>
                            <li>Unbedingt erforderliche Cookies</li>
                            <li>
                              Leistungs-Cookies
                            </li>
                            <li>
                              Funktionelle Cookies
                            </li>
                            <li>
                              Targeting Cookies
                            </li>
                          </ul>
                          <p>
                            Wir setzen Cookies nur, wenn das gültige Recht es ausdrücklich erlaubt oder nachdem wir Ihr Einverständnis hierfür über das Cookie-Banner oder über die Einstellungen erhalten haben. Sie können Ihre Cookie Einstellungen für jede Kategorie zu jeder Zeit verändern, indem Sie auf den Cookie-Einstellungen-Button weiter unten klicken (außer für die Kategorie unbedingt erforderliche Cookies, da diese zum Funktionieren der Website notwendig sind):
                          </p>
                          <div><button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie-Richtlinie</button></div>
                          <br />
                          <p>&nbsp;</p>
                          <div>
                            <div id="ot-sdk-cookie-policy"></div>
                          </div>
                          <h2>Links zu anderen Websites</h2>
                          <p>
                            Diese Website kann Links oder Querverweise zu anderen Websites enthalten. Bitte berücksichtigen Sie, dass wir die Cookies- / Tracking-Technologien anderer Websites nicht kontrollieren und diese Cookie-Richtlinie nicht für solche Websites gilt.
                          </p>
                          <h2>Kontaktaufnahme</h2>
                          <p>
                            Wenn Sie Fragen, Kommentare oder Bedenken bezüglich dieser Cookie-Richtlinie oder der Informationspraktiken dieser Website haben, nehmen Sie mit uns bitte Kontakt über die in der Datenschutzerklärung angegebenen Kontaktinformationen auf.
                          </p>
                          <h2>Änderungen dieser Cookie-Richtlinie</h2>
                          <p>
                            Bei Änderungen dieser Cookie-Richtlinie wird die überarbeitete Richtlinie auf dieser Website bekannt gegeben. Diese Cookie-Richtlinie wurde zuletzt am 13-05-2021 aktualisiert.
                          </p>
                          <p>&nbsp;</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
